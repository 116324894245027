<template>
    <div id="selection-accordion" class="fuel-body">
        <div no-body class="card">
            <div class="card-header" @click="$event.preventDefault()">
                <div class="accordion-header-location">
                    <div class="current-location">
                        <img src="@/assets/img/place.png" />
                        <div class="current-address">
                            <span>Station 1</span><br>
                            <p>{{address}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-b-toggle="'accordion-2'" class="card-header">
                <div class="accordion-chevron"></div>
                <div class="accordion-header">
                    <div class="accordion-header-text">
                        <img class="accordion-header-image" src="@/assets/img/picto_fuel_black.png" />
                        <span>{{$t("selectPump")}}</span>
                    </div>
                    <div class="accordion-header-selected-item">
                        <h5>{{selectedPumpLabel}}</h5>
                    </div>
                </div>
            </div>
            <b-collapse id="accordion-2" accordion="acc">
                <b-card class="accordion-card">
                    <div class="custom-selection">
                        <div v-for="pump in pumps"
                            :class="{'non-selected-item' : true , 'selected-item' : selectedPump == pump.value}"
                            :key="pump.value" @click="selectPump(pump.value, 'accordion-2')">
                            <img src="@/assets/img/pump.svg" />
                            <span>{{pump.label}}</span>
                        </div>
                    </div>
                </b-card>
            </b-collapse>
            <div v-b-toggle="'accordion-3'" class="card-header">
                <div class="accordion-chevron"></div>
                <div class="accordion-header">
                    <div class="accordion-header-text">
                        <img class="accordion-header-image" src="@/assets/img/fuel_black.png" />
                        <span>{{$t("selectFuelType")}}</span>
                    </div>
                    <div class="accordion-header-selected-item">
                        <h5 class="selected-fuel" :style="'background-color:' + selectedFuelObj.bg_color + '; color :' + selectedFuelObj.text_color">{{selectedFuelObj.label}}</h5>
                    </div>
                </div>
            </div>
            <b-collapse id="accordion-3" accordion="acc">
                <b-card class="accordion-card">
                    <div class="custom-selection custom-selection-fuels">
                        <div v-for="fuel in fuels"
                            :class="{'non-selected-item' : true , 'selected-item' : selectedFuel == fuel.value}"
                            :key="fuel.value" @click="selectFuel(fuel.value, 'accordion-3')" :style="'background-color:' + fuel.bg_color + '; color :' + fuel.text_color">
                            <span>{{fuel.label}}</span>
                        </div>
                    </div>
                </b-card>
            </b-collapse>
            <div v-b-toggle="'accordion-4'" class="card-header">
                <div class="accordion-chevron"></div>
                <div class="accordion-header">
                    <div class="accordion-header-text">
                        <img class="accordion-header-image" src="@/assets/img/card.png" />
                        <span>{{$t("selectpaymentMethod")}}</span>
                    </div>
                    <div class="accordion-header-selected-item">
                        <h5>{{card.cardNumber}}</h5>
                    </div>
                </div>
            </div>
            <b-collapse id="accordion-4" accordion="acc">
                <b-card class="accordion-card">
                    <div class="payment-method-selection">
                        <div class="payment-card" @click="closeAccordion('accordion-4')">
                            <div class="payment-card-infos">
                                <span>{{$t("cardNumber")}}</span>
                                <p>{{card.cardNumber}}</p>
                                <span>{{$t("validUntil")}}</span>
                                <p>{{$d(cardExpirationDate, "cardType")}}</p>
                            </div>
                            <div class="payment-card-images">
                                <img src="@/assets/img/societe_generale.png" />
                                <img src="@/assets/img/visa.png" />
                            </div>
                        </div>
                        <a class="add-button">
                            <img src="@/assets/img/picto_plus.png">
                        </a>
                    </div>
                </b-card>
            </b-collapse>
            <div class="card-header">
                <div class="pin" ref="pin">
                    <button @click="validForm" ref="valid" class="btn valid-button">{{$t("validButtonLabel")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var Stats = require("@/assets/js/Stats");
    import { sleep, generateFakeAddress} from "@/assets/js/Utils"

    export default {
        name: 'SelectionAccordion',
        components: {
        },
        data() {
            return {
                pumps: [
                    { label: this.$t("pump") + ' 1', value: 'pump1' },
                    { label: this.$t("pump") + ' 2', value: 'pump2' },
                    { label: this.$t("pump") + ' 3', value: 'pump3' },
                    { label: this.$t("pump") + ' 4', value: 'pump4' },
                    { label: this.$t("pump") + ' 5', value: 'pump5' },
                    { label: this.$t("pump") + ' 6', value: 'pump6' },
                ],
                selectedPump: "pump2",
                fuels: [
                    { label: 'Super Premier', value: 's_pre', bg_color:"#56BA4A", text_color:"white" },
                    { label: 'Super Premium', value: 's_ex', bg_color:"#014924", text_color:"white" },
                    { label: 'Diesel Premier', value: 'd_pre', bg_color:"#E4E712", text_color:"black" },
                    { label: 'Diesel Premium', value: 'd_ex', bg_color:"#F68620", text_color:"black" }
                ],
                selectedFuel: localStorage.getItem("DKC_fuel_user_favoriteFuel") || 's_pre',
                address: sessionStorage.getItem("DKC_fuel_pump_address") || generateFakeAddress(),
                card: {
                    cardNumber: "4971 **** **** **43"
                },
                newTab:null

            }
        },
        methods: {
            closeAccordion(accrodionId) {
                this.$root.$emit('bv::toggle::collapse', accrodionId);
            },
            selectPump(selectedPump, accordionId) {
                this.selectedPump = selectedPump;
                this.closeAccordion(accordionId);
            },
            selectFuel(selectedFuel, accordionId) {
                this.selectedFuel = selectedFuel;
                this.closeAccordion(accordionId);
            },
            validForm() {
                this.$parent.loader.content = this.$t("authenticationLoadingText");
                this.$parent.loader.showLoader = true;
                sleep(2000);
                this.newTab = window.open(this.$parent.base + "/3dsecure");
                this.newTab.addEventListener('pagehide', (e)=>{
                    if(e.target.URL != "about:blank"){
                        this.$parent.loader.content = this.$t("loadingText");
                        setTimeout(()=>{
                            if(!localStorage.getItem("DKC_3DS_back", false)){
                                setTimeout(()=>{
                                    this.$router.push({path: this.$parent.base + "/waiting"})
                                }, 1000)
                            }
                            localStorage.removeItem("DKC_3DS_back");
                            this.$refs.pin.focus();
                        }, 3000)
                    }
                })
            }
        },
        computed: {
            selectedPumpLabel() {
                var selected = this.pumps.filter(pump => pump.value == this.selectedPump);
                sessionStorage.setItem("DKC_selectedPump", JSON.stringify(selected[0]));
                return selected[0].label;
            },
            selectedFuelObj() {
                var selected = this.fuels.filter(fuel => fuel.value == this.selectedFuel);
                sessionStorage.setItem("DKC_selectedFuel", JSON.stringify(selected[0]));
                return selected[0];
            },
            cardExpirationDate() {
                var date = new Date();
                date.setUTCFullYear(date.getFullYear() + 5);
                return date;
            }
        },
        mounted(){
            Stats.setStats({ nbSelectionAccess : 1 })
        }
    }
</script>